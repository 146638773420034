import React, { useEffect, useState } from 'react';
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import ReactPlayer from "react-player";

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const StyledTextarea = styled.textarea`
  resize: none;
  width: 100%;
  overflow: hidden;
  outline: none;
  border: none;
  `;

function App() {

  const { authenticate, isAuthenticated, isAuthenticating, user, account, logout } = useMoralis();
  const Web3Api = useMoralisWeb3Api();
  const [haveNFT, setHaveNFT] = useState(false);
  const [giftcode, setGiftcode] = useState("");
  const [haveGift, setHaveGift] = useState(0);

  const handleChange = event => {
    setGiftcode(event.target.value);
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (!haveNFT) {
        fetchNFTsForContract();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const login = async () => {
    if (!isAuthenticated) {

      await authenticate({ signingMessage: "Log in using Moralis" })
        .then(function (user) {
          console.log("logged in user:", user);
          console.log(user.get("ethAddress"));
        })
        .catch(function (error) {
          console.log(error);
        });

      fetchNFTsForContract();
      setGiftcode("");
      setHaveGift(0);
    }
  }

  const logOut = async () => {
    await logout();
    setHaveNFT(false);
    setGiftcode("");
    setHaveGift(0);
    console.log("logged out");
  }

  const fetchNFTsForContract = async () => {
    if (!haveNFT) {
      const options = {
        chain: "polygon",
        token_address: "0x2953399124F0cBB46d2CbACD8A89cF0599974963",
      };
      const polygonNFTs = await Web3Api.account.getNFTsForContract(options);
      const found = polygonNFTs.result.find(res => res.token_id === '111376445703892400236464593581988237051786871452292827856249304263742701174794');
      if (found === undefined) {
        console.log("Not found");
        setHaveNFT(false);
      }
      else {
        console.log("FOUND!!");
        setHaveNFT(true);
      }
      console.log(found);
      console.log(polygonNFTs);
    }
  };

  const checkGiftcodeAndSaveAddressToKV = () => {
    if (giftcode === '1234567') {
      setHaveGift(1);
      fetch('https://lce-nft-gift.pages.dev/api/LCENFTRequest/' + user.get("ethAddress"));
      console.log("Save Address to KV");
    }
    else {
      setHaveGift(-1);
    }
  };

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={"/config/images/bg.png"}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo_lce.png"} />
        <s.SpacerSmall />
        <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
              }}>
        <p>富貴のこころ購入特典</p>
        </s.TextTitle>
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {isAuthenticated ? "LOGIN!" : "logout"}
            </s.TextTitle>
            {isAuthenticated ?
              (
                <>
                  {haveNFT ?
                    (
                      <>
                        <ReactPlayer
                          url="https://vimeo.com/778460245"
                          width="640px"
                          controls="true"
                        />
                      </>
                    ) : (
                      <>
                        <s.TextTitle
                          style={{ textAlign: "center", color: "var(--accent-text)" }}
                        >
                          NFTギフトコードを入力してNFTをゲット！
                        </s.TextTitle>
                        {haveGift === -1 ?
                          (
                            <>
                              <s.TextTitle
                                style={{ textAlign: "center", color: "var(--accent-text)" }}
                              >
                                NFTギフトコードが間違っています
                              </s.TextTitle>
                            </>
                          ) : (
                            <>
                              {haveGift === 1 ?
                                (
                                  <>
                                    <s.TextTitle
                                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                                    >
                                      NFTが届くまでお待ちください。通常24時間以内に届きます。
                                    </s.TextTitle>
                                    <StyledLink target={"_blank"} href={"https://opensea.io/account"}>
                                      確認についてはこちら
                                    </StyledLink>
                                  </>
                                ) : (
                                  <>
                                  </>
                                )
                              }
                            </>
                          )
                        }
                        <s.SpacerSmall />
                        <StyledTextarea
                          autoFocus value={giftcode} onChange={handleChange}
                        />
                        <s.SpacerSmall />
                        <StyledButton
                          onClick={(e) => {
                            e.preventDefault();
                            checkGiftcodeAndSaveAddressToKV();
                          }}
                        >
                          {"NFTギフトを受け取る"}
                        </StyledButton>
                      </>
                    )
                  }
                </>
              ) : (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    まずはMetamaskウォレットを接続してください。
                  </s.TextTitle>
                  <StyledLink target={"_blank"} href={"https://coincheck.com/ja/article/472"}>
                    Metamaskウォレットについてはこちら
                  </StyledLink>
                  <s.SpacerSmall />
                  <StyledButton
                    onClick={(e) => {
                      e.preventDefault();
                      login();
                    }}
                  >
                    {"ウォレット接続"}
                  </StyledButton>
                </>
              )
            }
            <s.SpacerSmall />
            <StyledButton
              onClick={(e) => {
                e.preventDefault();
                logOut();
              }}
            >
              {"ログアウト／ウォレット切断"}
            </StyledButton>
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            {haveNFT?(<>
              <p>取扱注意説明</p>
            </>):(<>
              <p>説明（テキスト・画像）</p>
            </>)}
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
